import React, { createContext, useCallback } from 'react'
import { DataType, JornadaType } from './props'
import { useLocalStorage } from "react-use";

const DEFAULT_VALUE = {
  data: {
    modalidade: '',
    souEmpresa: 'Não',
    usuario: {
      nome: '',
      empresa: '',
      cargo: '',
      cpfOuCnpj: '',
      email: '',
      telefoneFixo: '',
      telefoneMovel: '',
    },
    endereco: {
      cep: '',
      rua: '',
      numero: '',
      bairro: '',
      cidade: '',
      estado: '',
      complemento: ''
    },
    documentos: {
      anexoContratoSocial: '',
      anexoCartaoCnpj: '',
      anexoRg: '',
      anexoCpf: ''
    },
    dadosBancarios: {
      banco: '',
      codigo: '',
      agencia: '',
      contaCorrente: '',
      razaoSocial: '',
      cnpj: '',
      chavePix: '',
      anexoCabecalho: ''
    },
    formasDePagamento: [{}],
    equipamento: {
      modelo_id: '', 
      capacidade: '',
      placa: '',
      anexoDocumento: '',
      anexoLaudoTecnico: '',
      anexoCheckList: '',
      anexoArt: ''
    },
    operador: {
      nome: '',
      rg: '',
      cpf: '',
      cnh: '',
      anexoRg: '',
      anexoCpf: '',
      anexoCarteiraProfissional: '',
      anexoAso: '',
      anexoOs: '',
      anexoFichaDeRegistro: '',
      anexoComprovanteDeQualificacao: ''
    }
  },
  update: () => {},
}

const JornadaContext = createContext<JornadaType>(DEFAULT_VALUE);

const JornadaProvider: React.FC = ({ children }) => {
  const [data, setData] =  useLocalStorage<DataType>("jornada", DEFAULT_VALUE.data)

  const update = useCallback(
    (settings: DataType) => {
      setData(settings)
    },
    [setData]
  )

  return (
    //@ts-ignore
    <JornadaContext.Provider value={{ data, update }}>{children}</JornadaContext.Provider>
  );
}

export { JornadaProvider };
export default JornadaContext;
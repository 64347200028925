import { Fragment, useState } from 'react';
import { Container, Row, Col, Div } from '../../styled/globalStyles';
import { Wrapper, WrapperLink, Nav, WrapperMenuMobile } from './styled';
import { Link, useHistory } from 'react-router-dom';
import { Button, SidebarLocador, SidebarLocatario } from '../';
import { IoNotifications, IoMenuOutline } from 'react-icons/io5';
import logo from '../../assets/imagens/logo.jpg'; 
import { useAuth } from './../../context/auth/useAuth';
import { getFirstName } from './../../helpers/index'; 
 
export const Header = () => {  
  const [isLoading, setIsLoading] = useState(false)
  const [isMenuMobileActive, setIsMenuMobileActive] = useState(false);
  const history = useHistory()
  const { logout, user } = useAuth();

  const handleLogout = () => {
    setIsLoading(true)
    setTimeout(() => {
      logout() 
      history.push('/login');
      setIsLoading(false)
    }, 2000); 
  }

  const handleMenuMobile = () => {
    setIsMenuMobileActive(!isMenuMobileActive)
    document.getElementById('menu-mobile').style.display = isMenuMobileActive ? 'block' : 'none'
  }
 
  return (
    <Fragment>
      <Wrapper className="container-fluid">
        <Container className="container">
          <Row className="row">  
            <Col className="col-4 col-sm-4 col-md-4 col-lg-3 col-lx-3 col-xxl-3"> {/* d-xxl-block d-lx-block d-lg-block d-none */}
              Olá, {getFirstName(user.nome)}
            </Col>
            <Col className="col-8 col-sm-8 col-md-8 col-lg-9 col-lx-9 col-xxl-9 text-right">
              <WrapperLink className="d-flex justify-content-end">
                <Link to="#"> 
                  <IoNotifications size={25} /> 
                  <span>NOTIFICAÇÕES</span> 
                </Link>
                <Button 
                  label="SAIR" 
                  onClick={() => handleLogout()} 
                  loading={isLoading} 
                />
                <IoMenuOutline size={25} className="menu-mobile" onClick={() => handleMenuMobile()} />
              </WrapperLink> 
            </Col>
          </Row>
        </Container> 
      </Wrapper> 
      <WrapperMenuMobile id="menu-mobile" style={{ display: 'none' }}>
        { user.modalidade === 'Locador' ? <SidebarLocador /> : <SidebarLocatario /> } 
      </WrapperMenuMobile>
    </Fragment>
  )
}

export const HeaderCadastro = () => {
  return (
    <Div className="container-fluid pt-4">
      <Div className="container">
        <Div className="row">
          <Div className="col-12">
            <Link to="/">
              <img src={logo} alt="imagem - logomarca" title="GuindasApp" />
            </Link>
          </Div>
        </Div>
      </Div>
    </Div>
  )
}

export const HeaderLandingPage = () => {
  const history = useHistory();

  return (
    <Wrapper className="container-fluid"> 
      <Container className="container">
        <Row className="row d-flex align-items-center">  
          <Col className="col-3 col-sm-3 col-md-3 col-lg-3 col-lx-3 col-xxl-3">
            <img width={150} src={logo} alt="imagem - logomarca" title="GuindasApp" />
          </Col>
          <Col className="col-5">
            <Nav>
              <Link to="#" className="active">HOME</Link>
              <Link to="#">SOBRE</Link>
              <Link to="#">CLUBE BENEFÍCIOS</Link>
              <Link to="#">CONTATOS</Link>
            </Nav>
          </Col> 
          <Col className="col-4 text-right">   {/* col-sm-4 col-md-4 col-lg-4 col-lx-4 col-xxl-4  */}
            <WrapperLink className="d-flex justify-content-end" style={{ display: 'flex', gap: '20px' }}> 

              <Button label="CADASTRE-SE" size="medium" onClick={() => history.push('/criar-conta')} />

              <Button label="LOGIN" size="medium"  onClick={() => history.push('/login')}  />

              <IoMenuOutline size={25} className="menu-mobile" onClick={() => alert('mobile')} />
            </WrapperLink> 
          </Col>
        </Row>
      </Container> 
    </Wrapper>
  )
}
import { useState } from 'react'
import { Wrapper, Box } from './styled'

interface ISwitch {
  available: boolean 
}

export const Switch = ({ available }:ISwitch) => {
  const [isAvailable, setIsAvailable] = useState(available); 
  
  return (
    <Wrapper onClick={() => setIsAvailable(!isAvailable)}>
      <Box active={isAvailable ? true : false}>disponível</Box>
      <Box active={!isAvailable ? true : false}>indisponível</Box>
    </Wrapper>
  )
}
export const getFirstName = (name: string) => {
  let firstName = name.split(" "); 
 
  return firstName[0];
}

export const blobToBase64 = (url:any, callback:any) => {
  var xhr = new XMLHttpRequest();
  xhr.onload = function() {
    var reader = new FileReader();
    reader.onloadend = function() {
      callback(reader.result);
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}
import { Wrapper } from './styled'
import { P } from '../../styled/globalStyles'

export const Footer = () => {
  return (
    <Wrapper>
      <P>
        suporte@guindasapp.com.br<br />
        Suporte 0000-0000<br /><br />
        Copyright @ GuindasApp - 2021
      </P>
    </Wrapper> 
  )
}
import { JornadaProvider } from './jornada/context'
import { AuthProvider } from './auth/context'

const GlobalContext = ({ children }: { children : JSX.Element }) => {
  return (
    <JornadaProvider>
      <AuthProvider>
        {children}
      </AuthProvider> 
    </JornadaProvider>
  )
}

export default GlobalContext;
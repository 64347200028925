import { UL, LI } from './styled';
import { Link, useParams } from 'react-router-dom';

interface ParamTypes { id?: string }

export const SidebarLocador = () => {
  let { id } = useParams<ParamTypes>();
  const page = window.location.pathname

  return (
    <UL>   
      <Link to="/locador/servicos-em-execucao">
        <LI active={page === '/locador/servicos-em-execucao' || page === '/locador/'  ? true : false}>
          SERVIÇOS EM EXECUÇÃO
        </LI>
      </Link>
     
      <Link to="/locador/servicos-agendados">
        <LI active={page === '/locador/servicos-agendados' ? true : false}>
          SERVIÇOS AGENDADOS
        </LI>
      </Link>

      <Link to="/locador/meus-equipamentos">
        <LI 
          active={
            page === '/locador/meus-equipamentos' || 
            page === '/locador/meus-equipamentos/cadastrar' || 
            page === `/locador/meus-equipamentos/${id}/editar` ? true : false}
          >
          MEUS EQUIPAMENTOS
        </LI> 
      </Link>

      <Link to="/locador/meus-operadores">
        <LI 
          active={
            page === '/locador/meus-operadores' || 
            page === '/locador/meus-operadores/cadastrar' || 
            page === `/locador/meus-operadores/${id}/editar` ? true : false}
          >
          MEUS OPERADORES
        </LI>
      </Link>

      <Link to="/locador/dashboard">
        <LI  active={page === '/locador/dashboard' ? true : false}>
          DASHBOARD
        </LI>
      </Link>

      <Link to="/locador/meus-dados">
        <LI active={page === '/locador/meus-dados' ? true : false}>
          MEUS DADOS
        </LI> 
      </Link> 

      <Link to="#">
        <LI active={false}>
          CLUBE DE BENEFÍCIOS
        </LI>  
      </Link>
    </UL> 
  )
}
  
export const SidebarLocatario = () => {
  let { id } = useParams<ParamTypes>();
  const page = window.location.pathname

  return (
    <UL>   
      <Link to="/locatario">
      <LI  active={page === '/locatario' ? true : false}>
          INICIO
        </LI>  
      </Link>
      <Link to="/locatario/minhas-contratacoes">
      <LI active={page === '/locatario/minhas-contratacoes' ? true : false}>
          MINHAS CONTRATAÇÕES
        </LI>  
      </Link>
      <Link to="/locatario/servicos-agendados">
        <LI active={page === '/locatario/servicos-agendados' ? true : false}>
          SERVIÇOS AGENDADOS
        </LI>  
      </Link> 
      <Link to="/locatario/dashboard">
        <LI active={page === '/locatario/dashboard' ? true : false}>
          DASHBOARD
        </LI>
      </Link>
      <Link to="/locatario/meus-dados">
        <LI active={page === '/locatario/meus-dados' ? true : false}>
          MEUS DADOS
        </LI>
      </Link>
      <Link to="#">
        <LI active={false}>
          CLUBE DE BENEFÍCIOS
        </LI>  
      </Link>
    </UL> 
  )
}



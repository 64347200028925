import { Fragment } from 'react';
import { Header, HeaderCadastro, Footer, SidebarLocador, SidebarLocatario } from '../'
import { BodyRegister, ContainerFluid, Container, Row, Col } from '../../styled/globalStyles'
interface IMain {
  children: React.ReactNode
}

export const Main = ({ children }: IMain) => {
  return ( 
    <Fragment>
      <Header />   
        {children} 
      <Footer />
    </Fragment>
  )
}

export const MainLocador = ({ children }: IMain) => {
  return (
    <Main>
      <ContainerFluid className="container-fluid mt-4 mb-4">
        <Container className="container">
          <Row className="row">
            <Col className="col-12 col-sm-12 col-md-12 col-lg-3 col-lx-3 col-xxl-3 d-xxl-block d-lx-block d-lg-block d-none">
              <SidebarLocador />
            </Col>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-9 col-lx-9 col-xxl-9">
              {children}
            </Col>
          </Row>
        </Container> 
      </ContainerFluid> 
    </Main>
  )
}

export const MainLocatario = ({ children }: IMain) => {
  return (
    <Main>
      <ContainerFluid className="container-fluid mt-4 mb-4">
        <Container className="container">
          <Row className="row"> 
            <Col className="col-12 col-sm-8 col-md-8 col-lg-9 col-lx-9 col-xxl-9">
              {children}
            </Col>
            <Col className="col-12 col-sm-4 col-md-4 col-lg-3 col-lx-3 col-xxl-3 d-xxl-block d-lx-block d-lg-block d-none">
              <SidebarLocatario />
            </Col>
          </Row>
        </Container> 
      </ContainerFluid> 
    </Main>
  )
}

export const MainCadastro = ({ children }: IMain) => {
  return (
    <BodyRegister>
      <HeaderCadastro /> 
      <ContainerFluid className="container-fluid mt-4">
        <Container className="container">
          <Row className="row d-flex justify-content-center"> 
            {children}
          </Row>
        </Container> 
      </ContainerFluid> 
    </BodyRegister> 
  )
}
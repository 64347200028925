import { createContext } from 'react';
import { IAuthProvider, IContext, IUser } from "./types";
import { useLocalStorage } from "react-use"; 

const DEFAULT_VAUE = {
  user: {
    token: '',
    nome: '',
    modalidade: ''
  },
  authenticate: () => {},
  logout: () => {},
}

const AuthContext = createContext<IContext>({} as IContext);

const AuthProvider = ({ children }: IAuthProvider) => {
  const [user, setUser] =  useLocalStorage<IUser>("auth", DEFAULT_VAUE.user)

  async function authenticate(user: IUser) { 
    setUser({
      ...user,
      token: user.token,
      nome: user.nome,
      modalidade: user.modalidade
    }) 
  }

  function logout() { 
    setUser({
      ...user,
      token: '',
      nome: '',
      modalidade: ''
    })
  }

  return (
    <AuthContext.Provider value={{ user, authenticate, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider };
export default AuthContext;
import styled from "styled-components"; 
import { theme } from "../../styled/theme";

export const Wrapper = styled.header`
  background: ${theme.primary[0]};
  padding: 20px 0;
  color: #FFF;
`; 

export const WrapperLink = styled.div`
    
  svg {
    color: ${theme.secondary[0]};
    margin-right: 15px;
  }

  span {
    font-size: ${theme.fontSize.medium};
    color: #FFF;
    margin-right: 30px;
    font-weight: ${theme.fontWeight.medium};

    @media(max-width: 800px) {
      display: none;
    }
  }  
 
  .menu-mobile {
    cursor: pointer;
    margin-left: 20px;
    display: none;
    margin-top: 1px;

    @media (max-width: 800px) {
      display: block;
    } 
  }
`;  

export const Nav = styled.ul`
  padding: 0;
  margin: 0;

  display: flex;
  justify-content: space-between;

  a {
    color: #FFF;
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.fontSize.medium};

    :hover {
      color: ${theme.secondary[0]}
    } 
  }

  .active {
    color: ${theme.secondary[0]}
  }
`

export const WrapperMenuMobile = styled.div`
  background: ${theme.secondary[0]};
  padding: 20px;
`;
import { Suspense, lazy } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group'; 
import { LoadingPage } from './components'

// const currentKey = location.pathname.split('/')[1] || '/';

const currentKey = '';
const animationName = 'rag-fadeIn'
const timeout = { enter: 500, exit: 500 }; 
 
const LandingPage = lazy(() => import('./pages/landingPage'))
const Login = lazy(() => import('./pages/auth/login'));
const CriarConta = lazy(() => import('./pages/auth/modalidade'))

const ServicosEmExecucao = lazy(() => import('./pages/locador/servicosEmExecucao'));
const ServicosAgendados = lazy(() => import('./pages/locador/servicosAgendados') ); 
const MeusEquipamentos = lazy(() =>  import('./pages/locador/meusEquipamentos') );
const CadastrarEquipamento = lazy(() =>  import('./pages/locador/meusEquipamentos/cadastrar') );
const EditarEquipamento = lazy(() =>  import('./pages/locador/meusEquipamentos/editar') ); 
const MeusOperadores = lazy(() =>  import('./pages/locador/meusOperadores/') );
const CadastrarOperador = lazy(() =>  import('./pages/locador/meusOperadores/cadastrar') );
const EditarOperador = lazy(() =>  import('./pages/locador/meusOperadores/editar') );
const Dashboard = lazy(() =>  import('./pages/locador/dashboard') );
const MeusDados = lazy(() => import('./pages/locador/meusDados'));

const LocadorStep01 = lazy(() => import('./pages/locador/cadastro/step01'));
const LocadorStep02 = lazy(() => import('./pages/locador/cadastro/step02'));
const LocadorStep03 = lazy(() => import('./pages/locador/cadastro/step03'));
const LocadorStep04 = lazy(() => import('./pages/locador/cadastro/step04'));
const LocadorStep05 = lazy(() => import('./pages/locador/cadastro/step05'));
const LocadorStep06 = lazy(() => import('./pages/locador/cadastro/step06'));
const LocadorStep07 = lazy(() => import('./pages/locador/cadastro/step07'));
const LocadorStep08 = lazy(() => import('./pages/locador/cadastro/step08'));

const LocatarioStep01 = lazy(() => import('./pages/locatario/cadastro/step01'))
const LocatarioStep02 = lazy(() => import('./pages/locatario/cadastro/step02'))
const LocatarioStep03 = lazy(() => import('./pages/locatario/cadastro/step03'))
const LocatarioStep04 = lazy(() => import('./pages/locatario/cadastro/step04'))
const LocatarioStep04Boleto = lazy(() => import('./pages/locatario/cadastro/step04Boleto'))
const LocatarioStep04CartaoCredito = lazy(() => import('./pages/locatario/cadastro/step04CartaoCredito'))
const LocatarioStep05 = lazy(() => import('./pages/locatario/cadastro/step05'))
const LocatarioStep06 = lazy(() => import('./pages/locatario/cadastro/step06'))

const LocatarioHome = lazy(() => import('./pages/locatario/home'))
const LocatarioDashboard = lazy(() => import('./pages/locatario/dashboard'))
const LocatarioServicosAgendados = lazy(() => import('./pages/locatario/servicosAgendados'))
const LocatarioMeusDados = lazy(() => import('./pages/locatario/meusDados'))
const LocatarioMinhasContratacoes = lazy(() => import('./pages/locatario/minhasContratacoes'))
   
const Routes = () => (
  <Router> 
    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
      <Suspense fallback={<LoadingPage />}>
        <Switch> 

          <Route exact path="/" component={LandingPage} />

          <Route exact path="/criar-conta" component={CriarConta} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/auth/login" component={Login} /> 

          <Route exact path="/locador" component={ServicosEmExecucao} /> 
          <Route exact path="/locador/criar-conta" component={LocadorStep01} />
          <Route exact path="/locador/criar-conta/endereco" component={LocadorStep02} />
          <Route exact path="/locador/criar-conta/documentos" component={LocadorStep03} />
          <Route exact path="/locador/criar-conta/dados-bancarios" component={LocadorStep04} />
          <Route exact path="/locador/criar-conta/equipamento" component={LocadorStep05} />
          <Route exact path="/locador/criar-conta/operador" component={LocadorStep06} />
          <Route exact path="/locador/criar-conta/termos-de-uso" component={LocadorStep07} />
          <Route exact path="/locador/criar-conta/conclusao" component={LocadorStep08} /> 
          <Route exact path="/locador/servicos-em-execucao" component={ServicosEmExecucao} /> 
          <Route exact path="/locador/servicos-agendados" component={ServicosAgendados} /> 
          <Route exact path="/locador/meus-equipamentos" component={MeusEquipamentos} /> 
          <Route exact path="/locador/meus-equipamentos/cadastrar" component={CadastrarEquipamento} /> 
          <Route exact path="/locador/meus-equipamentos/:id/editar" component={EditarEquipamento} /> 
          <Route exact path="/locador/meus-operadores" component={MeusOperadores} /> 
          <Route exact path="/locador/meus-operadores/cadastrar" component={CadastrarOperador} /> 
          <Route exact path="/locador/meus-operadores/:id/editar" component={EditarOperador} /> 
          <Route exact path="/locador/dashboard" component={Dashboard} /> 
          <Route exact path="/locador/meus-dados" component={MeusDados} /> 

          <Route exact path="/locatario" component={LocatarioHome} /> 
          <Route exact path="/locatario/criar-conta" component={LocatarioStep01} />
          <Route exact path="/locatario/criar-conta/endereco" component={LocatarioStep02} />
          <Route exact path="/locatario/criar-conta/documentos" component={LocatarioStep03} />
          <Route exact path="/locatario/criar-conta/financeiro" component={LocatarioStep04} />
          <Route exact path="/locatario/criar-conta/financeiro/boleto" component={LocatarioStep04Boleto} />
          <Route exact path="/locatario/criar-conta/financeiro/cartao-credito" component={LocatarioStep04CartaoCredito} />
          <Route exact path="/locatario/criar-conta/termos-de-uso" component={LocatarioStep05} />
          <Route exact path="/locatario/criar-conta/conclusao" component={LocatarioStep06} /> 
          <Route exact path="/locatario/dashboard" component={LocatarioDashboard} /> 
          <Route exact path="/locatario/servicos-agendados" component={LocatarioServicosAgendados} />
          <Route exact path="/locatario/meus-dados" component={LocatarioMeusDados} />
          <Route exact path="/locatario/minhas-contratacoes" component={LocatarioMinhasContratacoes} />

        </Switch>
      </Suspense>
    </CSSTransition>
  </Router>
);

export default Routes; 